<template>
    <div>
        <div class="min-h-screen text-red-900 flex justify-center dlbox" style="height: 100vh; position: relative"
            v-if="isShow && isPCH5Text === 'PC'">
            <div class="max-w-screen-xl m-0 sm:m-24 sm:rounded-lg flex justify-center flex-1"
                style="background-color: transparent">
                <div class="flex-1 text-center hidden lg:flex">
                    <div>
                        <div style="
                color: #fff;
                font-size: 23px;
                margin-top: 10vh;
                margin-left: 10vw;
                text-align: left;
              ">
                            <h1 style="
                  font-size: 60px;
                  font-weight: 600;
                  margin-bottom: 5vh;
                  border-bottom: 5px solid #fff;
                  letter-spacing: 10px;
                ">
                                建<span style="font-size: 80px">筑</span><span style="color: #d1100b">+</span>共<span
                                    style="font-size: 80px">赢</span><span style="color: #d1100b">+</span>智慧云平<span
                                    style="font-size: 80px">台</span>
                            </h1>
                            <div style="letter-spacing: 5px">
                                <h5 style="margin-bottom: 1vh">
                                    这里将呈现给<span style="font-size: 40px">您</span>的是：
                                </h5>
                                <ul style="margin-left: 20px">
                                    <li class="liNum">项目与任务的管理</li>
                                    <li class="liNum">场景化应用的生态工具箱</li>
                                    <li class="liNum">快速便捷的数据查询与统计分析</li>
                                    <li class="liNum">工作中的协同</li>
                                    <li class="liNum">云存储</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="eltabs" class="lg:w-1/2 xl:w-3/12" style="margin: 280px 80px 0 0;padding-bottom: 20px;">
                <div style="border-radius: 15px; overflow: hidden">
                    <el-card body-style="padding: 50px;" v-if="isId">
                        <div slot="header" style="text-align: center;font-size: 20px;color: #D1100B;letter-spacing:2px">
                            <i style="cursor: pointer; width: 25px; height: 25px" class="el-icon-folder-opened"></i>
                            <span style="margin-left: 5px; line-height: 40px">文件分享</span>
                        </div>
                        <div style="text-align: center;margin-top: 20px;">
                            <el-input v-model="shareCode" placeholder="请输入分享码" style="width: 80%;"></el-input>
                        </div>
                        <div style="margin-top: 40px;text-align: center;">
                            <el-button style="width: 50%;" type="primary" @click="getDataInfo">提取文件</el-button>
                        </div>
                    </el-card>
                    <el-card body-style="padding: 50px;" v-else>
                        <div
                            style="height: 300px;text-align: center;line-height: 300px;font-size: 20px;color: #D1100B;">
                            当前页面分享链接错误，请联系顾问单位
                        </div>
                    </el-card>

                </div>
            </div>
            <div style="
          position: absolute;
          top: 0;
          width: 100vw;
          background: #fff;
          height: 10vh;
        "></div>
            <div class="Newlogo" style="
          width: 12vw;
          height: 10vh;
          padding: 0 0px;
          background-color: #fff;
          position: absolute;
          left: 10vw;
          display: flex;
          justify-content: space-around;
          align-items: center;
        ">
                <img style="height: 60%" src="../../../assets/筑赢台logo/正常.png" alt="" />
                <img style="height: 40%; margin-left: -5px" src="../../../assets/筑赢台logo/zytlogozi.jpg" alt="" />
            </div>

            <div class="footer">
                <p>
                    <span style="font-weight: bold">ICP备案号：</span><span
                        style="margin-right: 20px">京ICP备2021033771号</span>
                    <span style="font-weight: bold">技术支持<i class="el-icon-phone"
                            style="color: red;"></i>：</span><span>13120268450</span>
                </p>
                <p>
                    <span style="font-weight: bold">Copyright &copy;</span><span style="margin-right: 20px">2021-{{ new
                        Date().getFullYear() }}</span>
                    <span style="font-weight: bold">版权所有：</span><span>铂诺客智能科技（北京）有限公司</span>
                </p>
            </div>
        </div>
        <div v-if="isShow && isPCH5Text !== 'PC'" class="ExternalUserLogin" ref="ExternalUserLogin">
            <div style="display: flex;justify-content: center;margin-top: 40px;height: 70px;">
                <div>
                    <img src="@/assets/筑赢台logo/正常.png" alt="" style="width: 60px;height: 60px;">
                    <img src="@/assets/筑赢台logo/zytlogozi.jpg" alt="" style="width: 200px;height: 60px;">
                </div>
            </div>
            <el-card class="phoneLoginForm">

                <div
                    style="font-size: 24px;font-weight: bold;margin-bottom:10px;display: flex;justify-content: space-between;align-items: center;">
                    <span>文件分享</span>
                    <!-- <el-button type="text" style="font-size: 14px;" class="isuser" @click="isuser = !isuser">{{ isuser ?
                    '密码登录' : '验证码登录' }}</el-button> -->
                </div>
                <div style="text-align: center;margin-top: 20px;">
                    <el-input v-model="shareCode" placeholder="请输入分享码" style="width: 80%;"></el-input>
                </div>
                <div style="margin-top: 20px;text-align: center;">
                    <el-button style="width: 50%;" type="primary" @click="getDataInfo">提取文件</el-button>
                </div>


            </el-card>
            <div class="footer" v-if="!iskeyboard">
                <div><span style="font-weight: bold">ICP备案号：</span><span
                        style="margin-right: 20px">京ICP备2021033771号</span>
                </div>
                <div><span style="font-weight: bold">技术支持<i class="el-icon-phone" style="color: red;"></i>：</span><span
                        style="margin-right: 20px">13120268450</span><span style="font-weight: bold">Copyright&copy;
                    </span><span style="margin-right: 20px">2021-{{ new
                        Date().getFullYear() }}</span></div>
                <div><span style="font-weight: bold">版权所有：</span><span style="margin-right: 20px">铂诺客智能科技（北京）有限公司</span>
                </div>


            </div>
        </div>
        <div v-if="!isShow">

            <div
                style="height: 60px;width: 100%;background-color: #f1f1f1;display: flex;align-items: center;padding: 0 10px;position: sticky;top: 0;left: 0;z-index: 10;justify-content: space-between;">
                <div class="ellipsis2" style="width: calc( 100% - 110px);">
                    {{ shareInfo.SharingName }}
                </div>

                <el-radio-group size="mini" v-model="viewType" style="width: 110px;text-align: center;">
                    <el-radio-button label="card" :border="false"><i class="el-icon-menu"
                            style="font-size: 16px"></i></el-radio-button>
                    <el-radio-button label="list" :border="false"><i class="el-icon-s-operation"
                            style="font-size: 16px;font-weight: bold"></i></el-radio-button>
                </el-radio-group>

            </div>
            <el-main v-show="viewType == 'card'">
                <div class="center" v-if="cardList.length > 0">
                    <el-card :class="['proListCard', item.checked ? 'proListCardActive' : '']"
                        :body-style="{ padding: '0px' }" v-for="(item, index) in cardList" :key="index"
                        :title="item.FileName" :style="isPCH5Text !== 'PC' ? { width: '48%', margin: '1%' } : {}">
                        <el-image :src="item.FileType === 'image' ? item.FileWebURL : folderImg" class="proListImg"
                            fit="cover "></el-image>
                        <div style="height: 75px;box-sizing: border-box;">
                            <div style="padding: 0 5px;box-sizing: border-box; line-height: 20px;font-weight: bold;font-size: 12px;width: 100%;"
                                class="ellipsis1">
                                {{ item.FileName || '' }}
                            </div>
                            <div style="padding: 0 5px;box-sizing: border-box;line-height: 20px;font-size: 12px;width: 100%;"
                                class="ellipsis1">
                                <span>{{ item.CreateTime ? item.CreateTime.slice(0, 19) : '' || '' }}</span>
                            </div>
                            <div
                                style="padding: 0 5px;box-sizing: border-box;display: flex;width: 100%;justify-content: center;">


                                <el-button style="width: 50%;padding: 0;line-height: 25px;height: 25px;font-size: 12px;"
                                    @click.stop="download(item)" size="small" type="info"
                                    icon="el-icon-download">下载</el-button>

                            </div>

                        </div>
                        <div style="position: absolute;top: -2px;left: -2px;z-index: 10;" v-show="item.checked">
                            <div
                                style="width: 25px;height: 25px;background-color: #D1100B;font-size: 15px;text-align: center;color: #fff;display: flex;align-items: center;justify-content: center;border-radius: 6px;">
                                <i class="el-icon-circle-check"></i>
                            </div>
                        </div>
                    </el-card>
                </div>
                <el-empty description="暂无数据" v-else></el-empty>
            </el-main>
            <el-main v-show="viewType == 'list'">
                <div v-if="cardList.length > 0" class="center">
                    <el-table :data="cardList" style="width: 100%" border :header-row-style="{
                        padding: '10px !important;',
                    }" stripe v-if="isPCH5Text === 'PC'">

                        <el-table-column prop="FileName" label="文件名" min-width="300">
                        </el-table-column>

                        <el-table-column prop="FileType" label="文件类型" width="180"></el-table-column>
                        <el-table-column prop="CreateTime" label="上传日期" width="180">
                            <template slot-scope="scope">
                                {{ scope.row.CreateTime ? scope.row.CreateTime.slice(0, 19) : '' || '' }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="FileSize" label="文件大小" width="150">
                            <template slot-scope="scope">
                                {{ scope.row.FileSize / 1024 / 1024 > 1 ? (scope.row.FileSize / 1024 / 1024).toFixed(2)
                                    + 'MB' :
                                    (scope.row.FileSize / 1024).toFixed(2) + 'KB' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="130" align="center">
                            <template slot-scope="scope">

                                <el-button type="text" @click.stop="download(scope.row)"
                                    icon="el-icon-download">下载</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div v-if="isPCH5Text !== 'PC'" style="width: 100%;">
                        <el-card style="width: 100%;margin-bottom: 10px;border: 2px solid #f1f1f1;"
                            body-style="padding:5px 5px" v-for="(item, index) in cardList" :key="index">


                            <div style="display: flex;align-items: center;">

                                <div style="width: calc(100% - 70px);">
                                    <div style="font-size: 16px;width: 100%;font-weight: bold;" class="ellipsis2">
                                        {{ item.FileName }}
                                    </div>
                                    <div style="font-size: 12px;">
                                        <span>{{ item.FileSize / 1024 / 1024 > 1 ? (item.FileSize / 1024 /
                                            1024).toFixed(2)
                                            + 'MB' :
                                            (item.FileSize / 1024).toFixed(2) + 'KB' }}</span>
                                        <span style="margin-left: 5%;"> {{ item.FileType }}</span>
                                    </div>
                                    <div style="font-size: 12px;"> {{ item.CreateTime ?
                                        item.CreateTime.slice(0,
                                            19) : '' || '' }}
                                    </div>
                                </div>
                                <div style="width: 70px;">
                                    <div style="text-align: center;"><el-button type="text" @click.stop="download(item)"
                                            icon="el-icon-download">下载</el-button></div>

                                </div>
                            </div>



                        </el-card>

                    </div>
                </div>
                <el-empty description="暂无数据" v-else></el-empty>
            </el-main>
        </div>
    </div>
</template>

<script>


import "@/assets/Index/index.css";
import folderImg from "@/assets/folder.png";
export default {

    components: {

    },
    data() {
        return {
            folderImg,
            loading: false,
            shareCode: "",
            shareId: "",
            isShow: true,
            isId: false,
            shareInfo: {},
            cardList: [],
            viewType: 'card',
            isPCH5Text: "",
            Height: 0,
            iskeyboard: false,

        }
    },
    methods: {
        getSystemInfo() {
            if (/(micromessenger)/i.test(navigator.userAgent)) {
                return "微信"
            } else {
                // 判断h5还是pc true就是h5
                let client =
                    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    );
                if (client) {
                    return "H5"
                } else {
                    return "PC"
                }
            }
        },
        download(item) {
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            const elink = document.createElement("a");
            elink.download = item.FileName;
            elink.style.display = "none";
            elink.href = item.FileWebURL;
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href);
            document.body.removeChild(elink);
            loading.close();
        },
        getDataInfo() {
            if (!this.shareCode) {
                return this.$message.error('请输入分享码')
            }
            const loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.$http.post('/File_Manage/File_SharingInfo/GetDataListAndValidate', {
                "SharingInfoId": this.shareId,
                "SharingCode": this.shareCode
            }).then(res => {
                loading.close();
                if (res.Success) {
                    this.shareInfo = res.Data || {}
                    if (this.shareInfo.Id) {
                        this.isShow = false
                        this.cardList = this.shareInfo.FALList || []
                    }
                } else {
                    this.$message.error(res.Msg)
                }
            }).catch(err => {
                this.$message.error(err.Msg)
            })
        },
    },

    created() {
        if (this.$route.query.shareId) {
            this.shareId = this.$route.query.shareId
            this.isId = true
        }
        if (this.$route.query.psd) {
            this.shareCode = this.$route.query.psd
            this.getDataInfo()
        }
        this.isPCH5Text = this.getSystemInfo()
        window.addEventListener('resize', () => {
            this.isPCH5Text = this.getSystemInfo()
            let Height = window.innerHeight
            if (this.Height - Height > 0) {
                this.iskeyboard = true
            } else {
                this.iskeyboard = false
            }

            this.$nextTick(() => {
                if (this.$refs.ExternalUserLogin) {
                    this.$refs.ExternalUserLogin.style.height = window.innerHeight + 'px'
                }

            })
        })
        console.log(this.isPCH5Text, ' this.isPCH5Text this.isPCH5Text this.isPCH5Text')

    },
    computed: {

    },
};
</script>

<style lang="scss" scoped>
.footer {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    text-align: center;

    /* 设置背景色 */
    padding: 8px 0;
    /* 设置内边距 */
    font-size: 12px;
    /* 设置字体大小 */
    color: #666;
    /* 设置字体颜色 */
}

.dlbox {
    background-image: url("../../../assets/homebg1.jpg"),
        linear-gradient(to bottom right, #644ded, #811389) !important;
    background-size: 100% 95%;
    background-repeat: no-repeat;
}

.dlbox ::v-deep .el-tabs__item {
    height: 50px !important;
    line-height: 50px !important;
    font-size: 18px !important;
    letter-spacing: 2px !important;
}

::v-deep .qrcodebox .el-dialog {
    position: relative;
    margin: 0 auto 50px;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    width: 35%;
    padding: 0 0 43px 0;
}

/* 登录注册 */
.box1 .tiaokuan {
    display: block;
    justify-content: center;
    align-items: center;
}

.el-input--prefix .el-input__inner {
    border-radius: 18px;
}

.mark .box1 .btn1[data-v-fae5bece] {
    font-size: 20px;
}

//loginbox
.loginAndRegister {
    width: 100%;
    // min-height: 63vh;
    margin: 0 auto;

    .box1,
    .box2 {
        background-color: #ffffff;
        width: 100%;
        height: auto;
        margin: 0 auto;
        padding: 0 30px;
    }

    .box1 .el-form-item {
        margin-top: 20px;
    }

    .box1 .btn1 {
        font-size: 20px;
    }

    .box1 .el-form-item .imgs1 {
        width: 37px;
        margin-top: 11px;
        margin-left: -55%;
    }

    .box1 .Back {
        margin-left: 75%;
        color: #d1100b;
        font-size: 20px;
        display: flex;
        margin-top: -14%;
        cursor: pointer;
    }

    .box1 .revise {
        margin-left: 79%;
        color: rgb(95, 170, 255);
        font-size: 20px;
        display: flex;
        margin-top: -11.5%;
        cursor: pointer;
    }
}

.liNum {
    list-style-type: disc;
    text-indent: 20px;
}

.center {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
}

.proListCard {
    padding: 0;
    position: relative;
    // box-sizing: border-box;
    padding: 3px;
    border: 2px solid #ccc;
    width: 185px;
    height: 165px;
    margin: 5px;

    &:hover {
        box-shadow: 5px 5px 5px rgb(38 38 38 / 50%),
            5px 5px 10px rgb(38 38 38 / 30%);

        .delIcon {
            opacity: 1;
        }

        .star {
            opacity: 1;
        }
    }

    .proListImg {
        width: 100%;
        height: 90px;
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

}



.proListCard2 {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    padding: 3px;
    border: 2px solid #ccc;
    display: flex;
    position: relative;

    &:hover {
        box-shadow: 5px 5px 5px 5px rgb(38 38 38 / 50%),
            5px 5px 10px rgb(38 38 38 / 30%);

        color: #d1100b;
    }

    .proListImg {
        width: 200px;
        height: 90px;
    }
}

.proListCardActive {
    border: 2px solid #d1100b !important;
}

.ellipsis {
    overflow: hidden; //超出隐藏

    text-overflow: ellipsis; //文本超出时显示省略号

    display: -webkit-box;

    -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）
    word-wrap: break-word;
    word-break: normal;
    -webkit-line-clamp: 2; //内容限制的行数
}

.ellipsis4 {
    overflow: hidden; //超出隐藏
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis; //文本超出时显示省略号

    display: -webkit-box;

    -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

    -webkit-line-clamp: 4; //内容限制的行数
}

.ellipsis1 {
    overflow: hidden; //超出隐藏
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis; //文本超出时显示省略号

    display: -webkit-box;

    -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

    -webkit-line-clamp: 1; //内容限制的行数
}

.ellipsis2 {
    overflow: hidden; //超出隐藏
    word-wrap: break-word;
    word-break: normal;
    text-overflow: ellipsis; //文本超出时显示省略号

    display: -webkit-box;

    -webkit-box-orient: vertical; //子元素排列 vertical（竖排）orhorizontal（横排）

    -webkit-line-clamp: 2; //内容限制的行数
}

::v-deep.el-table thead tr th {
    padding: 10px 0px !important;
    background-color: #F5F7FA;
}

::v-deep .el-table__row--striped {
    background-color: #F5F7FA !important;
}

.el-main {
    padding: 20px 10px;
}

.ExternalUserLogin {
    width: 100vw;
    min-height: 100vh;
    padding: 10px;
    box-sizing: border-box;
    background: #eccece;
    position: relative;
    overflow: hidden;
}


.phoneLoginForm {
    // position: fixed;
    // top: 160px;
    // left: 5%;
    // width: 90%;
    margin-top: 40px;

}

::v-deep .el-input--suffix .el-input__inner {
    padding-right: 90px
}

::v-deep .el-form-item__label {
    line-height: 30px;
    padding: 0;
}
</style>
<style>
@keyframes banner {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-60vw);
    }
}
</style>